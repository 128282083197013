.carousel-section{
    background-image: url(./img/BG.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 160px 0 60px;
    margin-top: -160px;
}
.carousel-image {
    object-fit: cover;
}
.carousel .slide {
    cursor: pointer;
}
.carousel-section__container{
    width: 1170px;
    margin: 0 auto;
    margin-bottom: -120px;
}
.carousel-section .carousel-slider {
    border-radius: 16px;
    filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.1));
}

.carousel-section .dot{
    width: 4px !important;
    height: 4px !important;
    background: #4283C1 !important;
    opacity: 0.5 !important;
    border-radius: 30px !important;
}

.carousel-section .dot.selected{
    width: 39px !important;
    height: 4px !important;
    background: #4283C1 !important;
    border-radius: 30px !important;
}

@media (max-width: 1199px) {
    .carousel-section__container{
        max-width: 90%;
    }
}

@media (max-width: 1000px) {
    .carousel-section {
        padding-top: 100px;
    }
}