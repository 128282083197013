.countries {

}

.countries__container {
    max-width: 1130px;
    margin: 0 auto;
}

.countries__wrapper{
    padding: 80px 0 100px;
    background-color: #F2F4F6;
    border-radius: 20px;
    max-width: 90%;
    background-image: url("./img/005.png");
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.countries__col{
    float: left;
    width: 50%;
}

.countries__clearfix{
    clear: both;
}

.countries__title{
    font-size: 48px;
    line-height: 64px;
    margin-bottom: 65px;
}

.countries__list{
    column-count: 2;
    justify-content: space-between;
    column-width: 150px;
}

.countries__item {
    margin-bottom: 32px;
    align-items: center;
    color: #424E6F;
    font-size: 18px;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
}

.countries__item img {
    margin-right: 16px;
}

.countries__item span  {
    display: inline-block;
    vertical-align: super;
}

@media (max-width: 1199px) {
    .countries {
        padding: 50px 0 0;
    }
    .countries__container{
        max-width: 90%;
    }

    .countries__title{
        font-size: 30px;
        margin-bottom: 10px;
    }
}


@media (max-width: 800px) {
    .countries__col {
        width: 80%;
    }
}

@media (max-width: 740px) {
    .countries {
        padding: 30px 0 0;
    }
    .countries__title {
        font-size: 20px;
    }
    .countries__item {
        margin-bottom: 20px;
    }
}