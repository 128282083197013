.safa{
    padding: 115px 0;
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
}

.safa__container{
    width: 1170px;
    margin: 0 auto;
}

.safa__col{
    float: left;
    width: 50%;
}

.safa__clearfix{
    clear: both;
}

.safa__caption{
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.1em;
    margin-bottom: 16px;
}

.safa__title{
    font-size: 48px;
    line-height: 64px;
    margin-bottom: 16px;
}

.safa__desc{
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 65px;
    max-width: 80%;
}


.safa__stores {
    display: flex;
    margin-bottom: 25px;
}

.safa__stores a:first-child{
    margin-right: 25px;
}
.safa__info{
    padding-top: 100px;
}

.safa__phone{
    max-width: 550px;
}

@media (max-width: 1199px) {
    .safa{
        padding: 50px 0;
    }
    .safa__container{
        max-width: 90%;
    }

    .safa__phone{
        max-width: 80%;
    }

    .safa__info{
        padding-top: 5%;
    }

    .safa__title {
        font-size: 30px;
        margin-bottom: 0;
    }

    .safa__desc{
        margin-bottom: 20px;
    }
}

@media (max-width: 740px) {
    .safa{
        padding: 30px 0;
    }
    .safa__title{
        font-size: 20px;
    }

    .safa__desc{
        font-size: 16px;
    }

    .safa__stores{
        flex-direction: column;
    }
}

@media (max-width: 690px) {
    .safa__col{
        width: 100%;
    }
}

@media (max-width: 450px) {
    .safa__phone{
        max-width: 100%;
    }
}