.linked-button {
    padding: 10px 48px;
    background: linear-gradient(267.93deg, #3275B4 13.19%, #10398A 80.29%);
    box-shadow: 0px 10px 20px rgba(42, 103, 170, 0.2);
    border-radius: 4px;
    color: #fff;
    text-decoration: none;
    outline: none;
    opacity: 1;
    transition: .3s opacity;
    text-align: center;
}


@media (min-width: 450px) and (max-width: 740px) {
    .linked-button.guidelines-section__button {
        padding: 10px 20px;
    }
}
.linked-button > * {
    vertical-align: middle;
}

.linked-button:hover {
    opacity: .9;
}