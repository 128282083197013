.application{
    padding: 250px 0 60px;
    background-image: url("./img/Image.png");
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
}

.application__container{
    width: 1170px;
    margin: 0 auto;
}

.application__col{
    float: left;
    width: 50%;
}

.application__clearfix{
    clear: both;
}

.application__caption{
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.1em;
    margin-bottom: 16px;
}

.application__title{
    font-size: 48px;
    line-height: 64px;
    margin-bottom: 16px;
}

.application__desc{
    line-height: 32px;
    margin-bottom: 65px;
}

.application__qr{
    width: 120px;
    height: 120px;
    margin-right: 32px;
    float: left;
}

.application__qr img {
    max-width: 100%;
}

.application__links {
    overflow: hidden;
    padding-right: 100px;
    display: flex;
    flex-direction: column;
}

.application__stores {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.application__button img{
    margin-left: 10px;
}

.application__phone{
    max-width: 550px;
}


@media (max-width: 1199px) {
    .application__container{
        max-width: 90%;
    }

    .application__title{
        font-size: 30px;
        margin: 0;
    }

    .application__stores{
        flex-direction: column;
        margin-bottom: 10px;
    }

    .application__qr{
        margin-right: 15px;
    }
    .application__links{
        padding-right: 0;
    }

    .application__stores img{
        display: block;
        width: 100%;
        margin-bottom: 10px;
        max-width: 150px;
    }

    .application__button{
        padding: 10px;
    }

    .application__phone{
        max-width: 80%;
    }

    .application__desc{
        margin-bottom: 20px;
    }

    .application{
        padding: 200px 0 30px;
    }
}

@media (max-width: 740px) {
    .application__title{
        font-size: 20px;
    }

    .application__stores img{
        max-width: 200px;
    }

}

@media (max-width: 690px) {
    .application__col{
        width: 100%;
    }

    .application__row{
        display: flex;
        flex-direction: column-reverse;
    }

    .application__qr {
        float: none;
        text-align: left;
        margin: 15px 0;
        width: auto;
    }

    .application__qr img {
        max-height: 100%;
    }

    .application__stores{
        text-align: left;
    }

    .application__stores img{
        display: inline-block;
        max-width: 150px;
    }
}

@media (max-width: 690px) {
    .application__phone {
        max-width: 100%;
    }

}

