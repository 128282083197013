.footer {
    padding: 50px 0 40px;
    background: #ffffff;
}

.footer__container {
    width: 1170px;
    margin: 0 auto;
}

.footer__navigation ul{
    list-style: none;
}

.footer__top-section{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #F2F4F6;
    padding-bottom: 40px;
}

.footer__logo{
    flex: 1;
}

.footer__navigation{
    flex: auto;
}

.footer__navigation li{
    display: inline-block;
    vertical-align: middle;
    margin-right: 50px;
}

.footer__navigation a {
    color: #424E6F;
    text-decoration: none;
    opacity: 1;
    transition: .3s opacity;
}

.footer__navigation a:hover {
    opacity: .7;
}

.footer__bottom-section{
    padding: 20px 0 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer__sanofi{
    opacity: 0.5;
}
.footer__copyright{
    font-size: 10px;
    max-width: 35%;
    color: #9A9FAD;
}

@media (max-width: 1199px) {
    .footer{
        padding: 20px 0;
    }
    .footer__bottom-section{
        padding: 20px 0 0;
    }
    .footer__container{
        max-width: 90%;
    }
}

@media (max-width: 1000px) {
    .footer__navigation li {
        font-size: 12px;
        margin: 0 15px;
    }
}

@media (max-width: 690px) {
    .footer__navigation{
        display: none;
    }

    .footer__logo {
        text-align: center;
    }

    .footer__top-section{
        padding-bottom: 20px;
    }

    .footer__bottom-section{
        flex-direction: column;
        align-items: center;
    }

    .footer__copyright{
        margin-bottom: 20px;
    }
}

@media (max-width: 450px) {
    .footer__copyright{
        max-width: 80%;
    }
}


