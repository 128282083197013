.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-right: 150px;
    z-index: 2;
}

.header__container {
    max-width: 1170px;
    padding: 24px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.header__nav {
    margin: 0 -25px;
}

.header__nav ul {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
}

.header__nav li {
    list-style: none;
    margin: 0 25px;
}

.header__nav a {
    color: #fff;
    text-decoration: none;
    transition: .3s color;
}

.header__nav a:hover {
    color: #cdcdcd;
}

@media (max-width: 1199px) {
    .header {
        padding: 0;
    }
    .header__container{
        max-width: 90%;
    }

}

@media (max-width: 1000px) {
    .header__logo {
        max-width: 50px;
    }

    .header__logo a {
        display: inline-block;
    }

    .header__logo img{
        max-width: 100%;
    }

    .header__nav li {
        font-size: 12px;
        margin: 0 15px;
    }
}


@media (max-width: 740px) {
    .header__nav {
        display: none;
    }
}