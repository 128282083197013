.guidelines-section{
    padding: 120px 0 0 0;
    background-image: url("./img/bg.png");
    background-size: 40%;
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 120px;
}

.guidelines-section__container{
    width: 1170px;
    margin: 0 auto;
}

.guidelines-section__col {
    width: 50%;
    padding: 20% 0;
}

.guidelines-section__title{
    font-size: 48px;
    margin-bottom: 35px;
}

.guidelines-section__desc{
    margin-bottom: 65px;
    line-height: 2;
}

@media (max-width: 1199px) {
    .guidelines-section{
        background-size: 30%;
    }

    .guidelines-section__container{
        max-width: 90%;
    }

    .guidelines-section__col {
        width: 50%;
        padding: 50px 0;
    }

    .guidelines-section__title{
        font-size: 30px;
    }
}

@media (max-width: 740px) {
    .guidelines-section__title{
        font-size: 20px;
        margin-bottom: 10px;
    }

    .guidelines-section__desc{
        margin-bottom: 20px;
    }

    .guidelines-section{
        padding-top: 60px;
    }
}

@media (max-width: 450px) {
    .guidelines-section{
        background: none;
    }

    .guidelines-section__col{
        width: 100%;
    }

    .guidelines-section__title{
        max-width: 80%;
    }

    .guidelines-section__desc{
        max-width: 80%;
    }

    .guidelines-section__button{
        display: block;
    }
}