
.guidelines__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -40px;
}

.guidelines__item {
    width: calc(25% - 80px);
    margin: 0 40px 40px;
    color: #1D2842;
    text-decoration: none;
    display: block;
}

.guidelines__item img {
    max-width: 100%;
    filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.1));
    border-radius: 5px;
    margin-bottom: 10px;
}

.guidelines__wrapper {
    padding: 80px 120px;
}

@media (max-width: 1199px) {

    .guidelines__item{
        width: calc(25% - 40px);
        margin: 0 20px 20px;
    }
}

@media (max-width: 740px) {
    .guidelines{
        padding: 100px 0 50px;
    }

    .guidelines__title{
        font-size: 20px;
        margin-bottom: 0;
    }

    .guidelines__item{
        width: calc(33% - 40px);
        margin: 0 20px 20px;
    }

    .guidelines__list{
        margin: 0 -20px;
    }
}

@media (max-width: 450px) {
    .guidelines__item{
        width: calc(100% - 20px);
    }
}