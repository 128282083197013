.random-nutrition{
    position: relative;
    padding: 50px 0 300px;
    background-image: url("./img/BG.png");
    background-size: 75% 85%;
    background-position-x: right;
    background-repeat: no-repeat;
    color: #ffffff;
    z-index: 2;
    margin-bottom: -300px;
}

.random-nutrition__container{
    width: 1170px;
    margin: 0 auto;
}

.random-nutrition__col{
    width: 50%;
    float: left;
}

.random-nutrition__title{
    font-size: 64px;
    margin-bottom: 32px;
    margin-top: 20%;
}


.random-nutrition__desc{
    margin-bottom: 60px;
    line-height: 32px;
}

.clearfix{
    clear: both;
}

.random-nutrition__food{
    width: 70%;
    margin-top: 140px;
    filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.1));
    border-radius: 20px;
}

.random-nutrition__button img{
    margin-left: 10px;
}

@media (max-width: 1199px) {
    .random-nutrition{
        padding: 12% 0 50px;
        margin-bottom: -150px;
        min-height: 500px;
    }

    .random-nutrition__container{
        max-width: 90%;
    }
    .random-nutrition__food{
        max-width: 65%;
        margin: 0;
    }
    .random-nutrition__title{
        margin: 10px 0;
        font-size: 30px;
    }
}

@media (max-width: 740px) {
    .random-nutrition{
        padding-bottom: 30px;
    }
    .random-nutrition__title{
        font-size: 20px;
        margin-bottom: 20px;
    }

    .random-nutrition__desc{
        margin-bottom: 20px;
    }
}

@media (max-width: 690px) {
    .random-nutrition{
        background-size: cover;
        padding-bottom: 60px;
    }
    .random-nutrition__col{
        width: 100%;
    }
}

@media (max-width: 450px) {
    .random-nutrition__button{
        padding-left: 0;
        padding-right: 0;
        display: block;
    }

    .random-nutrition__food{
        max-width: 100%;
    }
}


