.event {
    width: 100%;
    display: flex;
    margin-bottom: 25px;
}
.study-colleft {
    width: 20%;
    padding-right: 30px;
    display: inline-flex;
}

.study-colleft img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}
.event-colright {
    width: 70%;
    padding-right: 30px;
    display: inline-block;
}
.event-colright h3 {
    font-size: 30px;
    font-family: "Questrial", sans-serif;
}
.event-colright p {
    font-size: 18px;
    font-family: "Questrial", sans-serif;
    font-weight: normal;
}
.event-colright p strong{
    font-family: "Questrial", sans-serif;
    font-weight: bold;
    font-size: 18px;
}
.event-colright p span{
    font-family: "Questrial", sans-serif;
    font-weight: normal;
    font-size: 17px;
}
.moreinfo-link {
    color: #3275B4;
    font-family: "Questrial", sans-serif;
    font-weight: bold;
    font-size: 18px;
    padding-top: 20px;
}
@media (min-width: 1200px) {

}
@media (max-width: 1199px) {

}

@media (max-width: 740px) {
    .event-colleft {
        width: 100%;
    }
    .event-colright {
        width: 100%;
    }
    .event {
        flex-wrap: wrap;
        margin-bottom: 25px;
    }
}

@media (max-width: 690px) {

}

@media (max-width: 450px) {
    .event-colleft {
        width: 100%;
    }
    .event-colright {
        width: 100%;
    }
}
