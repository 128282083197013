.lang-switcher{
    position: relative;
}

.lang-switcher__options{
    display: none;
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    right: 0;
}

.lang-switcher__options.active{
    display: block;
}

.lang-switcher__selected-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
}

.lang-switcher__selected-item img{
    margin: 0 8px;
}

.lang-switcher__arrow{
    transform: rotate(0deg);
    transition: .3s transform;
}

.lang-switcher__selected-item.active .lang-switcher__arrow{
    transform: rotate(180deg);
}

.lang-switcher__options {
    padding: 15px 8px 0;
    background: #fff;
    border-radius: 5px;
    filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.1));
}
.lang-switcher__option {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 4px 0 15px;
    cursor: pointer;
    transform: translateY(0px);
    transition: .3s transform;
}

.lang-switcher__option:hover {
    transform: translateY(-2px);

}

.lang-switcher__options {
    margin: -4px 0;
}

.lang-switcher__option img {
    margin-right: 8px;
}

.lang-switcher__flag {
    width: 25px;
}

@media (max-width: 1000px) {
    .lang-switcher {
        font-size: 12px;
    }
}