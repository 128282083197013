.page {
    padding: 160px 0;
    margin-top: -160px;
    background-image: url("./img/BG.jpg");
    background-size: 100% 50%;
    background-repeat: no-repeat;
    background-position: top;
    min-height: 500px;
}

.page__title {
    font-size: 48px;
    line-height: 64px;
    margin-bottom: 32px;
}
.page__container {
    width: 1170px;
    margin: 0 auto;
}

.page__wrapper {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 40px 30px 30px 30px;
}

@media (max-width: 1199px) {
    .page__container{
        max-width: 90%;
    }

    .page__title{
        font-size: 30px;
    }

    .page__wrapper{
        padding: 30px;
    }

}

@media (max-width: 740px) {
    .page{
        padding: 100px 0 50px;
    }

    .page__title{
        font-size: 34px;
        margin-bottom: 20px;
    }
}
@media (max-width: 540px) {
    .page__title{
        font-size: 30px;
        margin-bottom: 10px;
    }
}