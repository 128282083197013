.aboutus {
    padding: 0 0 100px;
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
}

.aboutus__container {
    width: 1370px;
    margin: 0 auto;
}
.aboutus__container h3,
.aboutus__container h2,
.aboutus__container h1
{
    font-size: 48px;
    line-height: 64px;
    margin-bottom: 16px;
    font-family: "Questrial", sans-serif;
    font-weight: normal;
}
.aboutus__container p,
.aboutus__container div {
    line-height: 32px;
    font-family: "Questrial", sans-serif;
    font-size: 16px;
    font-weight: normal;
}

.aboutus-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.aboutus-col {
    width: 80%;
    display: inline-block;
    justify-content: space-between;
}
.aboutus-col-right {
    height: auto;
    display: flex;
    justify-content: flex-end;
}
.about-us-img {
    max-width: 70%;
    height: auto;
    object-fit: contain;
}

@media (min-width: 1200px) {

}
@media (max-width: 1199px) {

    .aboutus__container {
        max-width: 90%;
        margin: 0 auto;
    }

}

@media (max-width: 740px) {
    .aboutus__container {
        width: 100%;
        margin: 0 auto;
    }
    .aboutus-row {
        flex-wrap: nowrap;
    }
    .aboutus-col {
        width: 100%;
        display: inline-block;
    }
}

@media (max-width: 740px) {
    .aboutus-row {
        flex-wrap: wrap;
    }
    .aboutus-col {
        width: 100%;
    }
}

@media (max-width: 450px) {

}
